const navigation = [
  {
    id: "5258d430-d729-44b1-96ad-484c005d",
    name: "Dashboard",
    slug: "dashboard"
  },
  {
    id: "94596dd2-3e95-4587-bdfd-4f48af31b06d",
    name: "Products",
    slug: "products"
  },
  {
    id: "2fa83d93-e460-4298-a07d-70d0b3sss642397",
    name: "Enquiry",
    slug: "enquiry"
  },
  {
    id: "2836aafa-6733-4b25-a908-ffca75b3e84f",
    name: "Contact Us",
    slug: "contactus"
  }
];

export default navigation;
